/* breakpoints */
$breakpoints: (
    'viewport-sm': 480px,
    'viewport-md': 768px,
    'viewport-lg': 992px,
    'viewport-xl': 1200px
);


$border-radius: 20px;
$border-width: 1px !default;

$margin-base: 2rem;
$padding-base: 2rem;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;


// Buttons
//
// Define Colors

$button-color-highlight: $green-sulu;
$button-color-default: map-get($theme-colors, 'primary' );
$button-color-shadow: $green-sushi;
$button-color-hover: $green-verdun;
$button-color-warning: map-get($theme-colors, 'warning' );
$button-color-warning-hover: $red-guardsman;
$button-color-disabled: map-get($theme-colors, 'disabled' );

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-border-width:                $border-width !default;

$alert-bg-level:                    -10 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 6 !default;