
.mat-form-field-appearance-legacy {
    &.mat-form-field-can-float {
        &.mat-form-field-should-float { 
            .mat-form-field-label-wrapper {
                top: -2em;

                .mat-form-field-label {
                    display: block;
                    font-family: $font-sans-condensed;
                    font-weight: $font-weight-bold;
                    font-size: 1.75rem; // override something going on within Material TODO look more into this
                    margin-bottom: 1.275em;
                    color: $gray-dark;
                }
            }
        }
        &.label-large {
            .mat-form-field-infix {
                width: 240px;
            }

            .mat-form-field-label-wrapper {
                padding-bottom: 1.75em;
                top: -3em;

                .mat-form-field-label {
                    line-height: $font-size-xl;
                    transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px);   
                    
                    mat-label {                 
                        font-size: $font-size-xl;
                    }
                }
            }
        }
    }
}

.mat-checkbox-layout {
    margin-bottom: 0;
}

.mat-error {
    margin-bottom: 1rem;

    &.error-arrow {
        font-family: $font-sans;
        background: $gray-medium-dark;
        padding: 1rem;
        position: relative;
        text-align: center;
        color: $white;

        &::after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $gray-medium-dark;
            border-width: 15px;
            margin-left: -15px;

        }
    }
}

mat-form-field {

    &[floatLabel="always"] {
        .mat-form-field-wrapper {
            select {
                height: 3rem;
            }

            .mat-form-field-label-wrapper {
                top: -.475em;
            }
        }
    }

    .mat-form-field-flex {
        min-height: 29.5px;
    }

    &.mat-focused {
        .mat-form-field-ripple {
            background-color: transparent !important;
        }
    }
    .mat-form-field-infix {
        border-top: 0;
        padding: 0 !important;
    }
    &.mat-form-field-appearance-legacy {
        .mat-form-field-underline { 
            background-color: $black;
        }

        .mat-form-field-wrapper {
            padding-bottom: 1.34375em;
        }
    }

}

.mat-calendar-body-selected {
    background-color: $gray-dark;
}

.mat-datepicker-toggle-active {
    color: $gray-light;   
}