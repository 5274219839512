.mat-stepper-horizontal, 
.mat-stepper-vertical,
.mat-sort-header-button,
td.mat-cell, 
td.mat-footer-cell, 
th.mat-header-cell {
    font-family: $font-sans;

    span {
        &.column-width {
            display: inline-block;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
}