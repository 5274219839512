// body { margin:0;position:fixed;top:0;right:0;bottom:0;left:0; }
/* Common */
.data-viz-box {
  box-shadow: 0px 3px 10px #BFBFBF;
  border-radius: 8px;
  overflow-x: scroll;

  h4 {
    font-size: $font-size-l; 
    margin: 1rem 0;
  }

  ul {
    
    li {
      h5 {
        font-family: $font-sans;
        color: $gray-medium;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      svg {
        margin-right: 0.5rem;

        + span {
          display: inline-block;
          color: $gray-medium;
          font-size: $font-size-base;
          font-weight: $font-weight-normal;

        }
      }
    }
  }
}


// Grid Lines
.grid {
  .tick {
    line {
      stroke: $gray-light;
      stroke-opacity: 0.7;
      shape-rendering: crispEdges;
    }

    text {
      display: none;
    }
  }
}

.tick text {
  fill: $gray-medium;
  font-size: 21px;
}

/* Line Chart */
#visualisation,
svg.line-graph-viz {
    overflow-x: scroll;
  
      .grid {
        .tick {
          line {
            stroke: $gray-light;
            stroke-opacity: 0.7;
            shape-rendering: crispEdges;
          }
        }
      }
        
      > path {
        stroke-linecap: round;
      }
      
      /* #visualisation > path,
      .x.axis {
        
      } */
      
      path.domain,
      .axis .tick line {
        stroke: none;
      }
  
      .marker {

        .marker-bg {
          fill: $green-yellow-green;
          }
    
        path {
          stroke: $green-yellow-green;
          stroke-width: 5;
          }
        
        text {
          font-size: 2.25rem;
          font-family: sans-serif;
          fill: $black;
        }

        .marker-tooltip {
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
          font-size: 1rem;
          padding: 10px;
          background-color: $white;
          border-radius: 5px;
          box-shadow: 0 0 4px $black;
          z-index: 100;
        }

        &:hover {
          .marker-tooltip,
          .marker-tooltip-box {
            opacity: 1;
          }
        }

        rect {
          z-index: 10;
        }

      }
    
  }

/*  Bar Graph */
#chart {
  rect.bordered {
    stroke: $gray-lightest;
    stroke-width: 2px;
    }
  
  .axis path,
  .axis line {
    fill: none;
    stroke: $black;
    shape-rendering: crispEdges;
  }
    
  path.domain,
  .axis .tick line {
    stroke: none;
  }
  
  .x.axis path {
    display: none;
  }
}
