.mwlResizable {

    position: relative;
    box-sizing: border-box; // required for the enableGhostResize option to work

    &.resize-active {
        + .row {
            display: none;
        }
    }

    .resize-handle-bottom {
        position: absolute;
        height: 0.5rem;
        cursor: row-resize;
        width: 100%;
        bottom: 0;
    
        svg {
            height: 1rem;
            position: absolute;
            left: calc(50% - 2.25rem);
            top: -0.25rem;

        }
    }

    &.resize-active,
    &.resize-hover {
        .resize-handle-bottom {
            background-color: $gray-lightest;
        }
    }

    &.userResizable {
        height: 600px;

        .resize-handle-bottom {
            max-width: 50%;

            svg {
                left: calc(50% - 0.563rem);
            }
        }
    }
    &.companyResizable {
        height: 944px;
    }
}