*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html { 
    font-size: $font-size-base; 
    font-family: $font-sans;
    letter-spacing: $letter-spacing-body;
    line-height: $line-height-lg;
    height: 100%;
} 

body {
    height: 100%;
}

table {
    width: 100%;
}


ul, ol {
    margin: 0 0 $margin-base;
    padding: 0;
}

li {
    font-weight: $font-weight-bold;
}

ul {
    li {
        list-style: none;
    }
}

ol {
    li {

    }
}

a {
    text-decoration: none;
}

hr {
    border-top: $gray-lightest 1px solid;
}