$white: #fff;
$gray-lightest: #E6E6E6;
$gray-light: #C4C4C4;
$gray-medium: #767676;
$gray-medium-dark: #5F5F5F;
// $gray-medium-darkest: #707070;
$gray-dark: #4a4a4a;
$black: #000;


/* colors */
$brand-green: #8CC64B;
$green-sulu: #c3ee8a;
$green-sushi: #7AAB37;
$green-yellow-green: #8CC63E;
$green-verdun: #3c551a;

$red-torch: #f22222;
$red-flame: #8a2d2e;
$red-sunset: #C54A4B;
$red-guardsman: #8C2D2E;
$red-tickleme-pink: #F98E8F;

$blue-wisteria: #CA87D5;
$blue-stone: #104a66;
$blue-allports: #1c6778;
$blue-pelorous: #2894ab;
$blue-turquoise: #70D8EE;

$violet-cesuir: #92629a;
$violet-lavender: #f4d0f9;

$yellow-dark-goldrenrod: #a46803;
$yellow-grandis: #FFD071;

$orange: #ffaa00;

$primary:       $green-yellow-green !default;
$warning:       $red-sunset !default;
$disabled:      $green-sulu !default;
$light:         $gray-light !default;
$medium:        $gray-medium !default;
$dark:          $gray-dark !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "warning":    $warning,
    "disabled":   $disabled,
    "light":      $light,
    "medium":     $medium,
    "dark":       $dark
  ),
  $theme-colors
);
