
/* @function breakpoint-max($name){
    $min: map-get($breakpoints, $name);    
    @return if($min != 0, $min, null);
} 

@function breakpoint-max($name){
    $max: map-get($breakpoints, $name);  
    @return if($max != 0, $max, null);
} 
 */
 
@mixin breakpoint-min($name) {
    $min: map-get($breakpoints, $name);
    @media (min-width: $min) { @content ; }
} 

@mixin breakpoint-max($name) {
    $max: map-get($breakpoints, $name);
    @media (max-width: $max) { @content ; }
} 

@mixin alert-variant($background, $border, $color) {
    color: $color;
    background-color: $background;
    border-color: $border;
  
    hr {
      border-top-color: darken($border, 5%);
    }
  
    .alert-link {
      color: darken($color, 10%);
    }
  }
  