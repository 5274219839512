h1,
h2, 
label {
    &.has-tooltip {
        display: flex;
        line-height: 1em;
        align-items: center;
    }
}

label {
    display: block;
    font-family: $font-sans-condensed;
    font-weight: $font-weight-bold;
    font-size: $font-size-l;
    margin-bottom: 1.275em;
    // text-transform: uppercase;
    color: $gray-dark;

    &.label-large {
        font-size: $font-size-xl;
    }  
}

input[type="text"],
input[type="number"],
input[type="time"],
input[type="date"],
input[type="password"] {
    border: none;
    border-bottom: 1px solid $black;
    margin: 0 0 $margin-base;
    width: 250px;
    max-width: 100%;
    padding: 0.125rem 0;
    color: $gray-dark;

    &::placeholder {
        color: $gray-medium;
    }
}

input[type="text"] {
    width: 250px;
}

input[type="number"],
input[type="time"],
input[type="date"] {
    width: auto;
    letter-spacing: 2px;
}

input[type="time"] {
    letter-spacing: 5px;
}

input[type="number"] {
    text-align: center;
}

select {
	display: block;
	color: $gray-medium;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0 0 $margin-base;
	appearance: none;
	background: url(/../../assets/images/arrow_drop_down_24px.svg) 100% 5px no-repeat transparent;
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    width: 250px;
    max-width: 100%;
    padding: 0.125rem 0;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #888;
}
select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
select option {
	font-weight:normal;
}
