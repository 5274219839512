app-sponsorship-create, app-economic-model-create, app-economic-model-create-dialog {
    .mat-form-field-infix {
        width: 400px;
        max-width: 100%;
    }

    .btn-upload {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        + label {
            font-size: 1.25em;
            font-weight: 700;
            color: $gray-dark;
            background-color: $gray-lightest;
            display: inline-block;
            border-radius: 5px;
            cursor: pointer; /* "hand" cursor */

            width: 100%;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                position: relative;

                &::before {
                    content: '+';
                    position: absolute;
                    left: -1rem;
                    top:0
                }
            }

        }

        &:focus + label {
            outline: 1px dotted $black;
            outline: -webkit-focus-ring-color auto 5px;
        }

        &:focus + label,
        + label:hover {
            background-color: $gray-medium;
        }
    }
}

.small-image-preview {
    width: 300px;
    max-width: 100%;

    img {
        max-width: 100%;
    }
}

.sponsorship-preview, .image-preview {
    width: 400px;
    max-width: 100%;

    img {
        max-width: 100%;
    }
}

.sponsorships-list-image {
    max-width: calc(54px + 1.5rem);
    padding: 0.75rem;
}

.sponsorships-table {
    .mat-checkbox-checked.mat-accent  {
        .mat-checkbox-background {
            background-color: $green-yellow-green;
        }
    }
}

