.announcements-list {

    .mat-expansion-panel-header {
        padding: $padding-base/2 $padding-base 0;
    }

    .mat-expansion-panel-content {
        padding: $padding-base/2 $padding-base 0;
        
        .mat-expansion-panel-body {
            padding: 0 0 $padding-base;
            max-height: 250px;
            overflow-y: scroll;
    
            .accouncement-date {
                color: $gray-medium-dark;
                font-size: $font-size-base;
            }
        }
    }
}