mat-horizontal-stepper {
    .mat-horizontal-stepper-header-container {

        position: relative;

        &::before {
            content: ' ';
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 10;
        }

        /* Hide on Edit Game screen */
        .game-form-edit-game & {
            display: none;
        }

        .mat-step-header {
            &.mat-horizontal-stepper-header {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }

            &.step-disabled {
                opacity: 0.75;
            }
            .mat-step-icon {
                padding: 1.25rem;

                &.mat-step-icon-content {
                    font-size: $font-size-l;
                }
            }

            .mat-step-label {
                font-size: $font-size-l;
                font-weight: $font-weight-bold;
                font-family: $font-sans;
                color: $gray-dark;

                &.mat-step-label-active {
                    color: $gray-dark;
                }

                &.cdk-keyboard-focused,
                &.cdk-program-focused,
                &:hover {
                    background: transparent;
                }
            }
            .mat-step-icon {
                padding: 1rem;
                background-color: $gray-light;

                &.mat-step-icon-selected, 
                &.mat-step-icon-state-done, 
                &.mat-step-icon-state-edit {
                    background-color: $brand-green;
                }
            }

            &.cdk-program-focused,
            &:hover {
                background: transparent;

                .mat-step-icon {
                    background-color: $gray-dark;
                }
            }
        }
    }

    .mat-horizontal-content-container {
        padding-left: 0;
        padding-right: 0;
    }
}