/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "src/assets/scss/styles.scss";

td, th {
    text-align: center !important;
    vertical-align: middle !important;
}

.mat-sort-header-container {
    justify-content: center;
}

mat-cell,
.mat-cell,
mat-footer-cell,
.mat-footer-cell,
mat-header-cell, .mat-header-cell {
  padding: 0.3rem !important;
}