$nav-color-default: $gray-light;
$nav-color-hover: $gray-dark;
$nav-color-active: $green-yellow-green;
nav {
    > ul {
       > li {
            width: 100%;
            margin-bottom: $margin-base;

            &.nav-dashboard {
                a {
                    &::before {
                        background-image: url(/../../assets/images/home-24px.svg);
                    }
                }
            }
            &.nav-games {
                a {
                    &::before {
                        background-image: url(/../../assets/images/emoji_events-24px.svg);
                    }
                }
            }
            &.nav-users {
                a {
                    &::before {
                        background-image: url(/../../assets/images/person-24px.svg);
                    }
                }
            }
            &.nav-areas {
                a {
                    &::before {
                        background-image: url(/../../assets/images/map-24px.svg);
                    }
                }
            }
            &.nav-brackets {
                a {
                    &::before {
                        background-image: url(/../../assets/images/brackets.svg);
                    }
                }
            }
            &.nav-competitions {
                a {
                    &::before {
                        background-image: url(/../../assets/images/competitions.svg);
                    }
                }
            }
            &.nav-sponsors {
                a {
                    &::before {
                        background-image: url(/../../assets/images/sponsorship-24px.svg);
                        width: 28px;
                        margin: 0 3px;
                    }
                }
            }

            a {
                font-family: $font-sans-condensed;
                font-size: $font-size-l;
                font-weight: $font-weight-bold;
                color: $black;
                letter-spacing: $letter-spacing-body;
                cursor: pointer;
            }

            > a {
                display: block;
                position: relative;
                background: $nav-color-default;
                background-size: 88px;
                border-radius: $border-radius;
                border: none;
                max-width: 100%;
                padding: 24px 24px 24px 100px;
                text-transform: uppercase;
                width: 100%;

                &::before {
                    content: "";
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    left: 46px;
                    top: 23px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }

                &:hover:not(.router-link-active) {
                    background-color: $nav-color-hover;
                    color: $white;

                    + ul {
                        background-color: $nav-color-hover;

                        > li {
                            a {
                                color: $white;
                            }
                        }
                    }
                }

                + ul {
                    display: none;
                }

                &.router-link-active {
                    background-color: $nav-color-active;

                    &.has-children {
                        border-radius: $border-radius $border-radius 0 0;
                    }

                    + ul {
                        background-color: $nav-color-active;
                        border-radius: 0 0 $border-radius $border-radius;
                        display: block;
                        padding: 0 24px 24px 100px;
                        margin-bottom: 0;

                        li {
                            margin-bottom: 22px;

                            &:last-child {
                                margin-bottom: 6px;
                            }

                            a {
                                &.router-child-link-active {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.link-list {
        ol {
            list-style: none;

            a {
                color: $gray-dark;
                text-decoration: underline;
                font-weight: normal;
                text-transform: uppercase;
            }
        }
    }
}

@media (max-width: '1500px') {
    nav {
        > ul {
            > li {
                > a {
                    padding-left: 75px;

                    &::before {
                        left: 25px;
                    }
                    &::after {
                        width: 75px;
                        top: 0px;
                    }

                    &.router-link-active {
                        + ul {
                            padding-left: 75px;
                        }
                    }
                }
            }
        }
    }
}
