.mat-table {
    tbody {
        tr {
            &.mat-row {
                &.linkStateActive {
                    // cursor: pointer;
                }
            }

            td:not(.mat-column-select) {
                &.mat-cell,
                &.mat-footer-cell,
                &.mat-header-cell {
                        min-width: 80px;
                }
            }
        }
    }
}

.mat-selectable {
    tbody {
        tr {
            &.mat-row {
                cursor: pointer;
            }
        }
    }
}
