$sidebar-width : 472px;
$sidebar-width-sm : $sidebar-width - 122;

.container {
    margin: 1.5*$padding-base 3*$padding-base;
}
.container-list {
    margin: 1.5*$padding-base 0;
}
.list-box {
    margin-bottom: $margin-base;
}

.grid-container {
    display: flex;
    min-height: 100%;

    h1 {
        margin: 0;
        line-height: 1em;;
    }

    .role-status {
        color: $gray-medium;
        font-size: $font-size-xl;
        margin: 0 0 10px 10px;
        line-height: $line-height-md;
    }

    header {
        &.header-light {
            > div {
                border-bottom: 1px solid $black;
            }

            h1 {
                color: $gray-light;
            }
        }

        .container,
        .container-list  {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .container,
    .container-list {
        padding: 1rem 2rem;

        header,
        .mat-expansion-panel-header-title{
            h1 {
                font-size: $font-size-xl;
            }

            .role-status {
                font-size: $font-size-l;
            }

            .container {
                padding: 0;
            }
        }

        .container,
        .container-list {
            padding: 0;
            margin:0;
        } 
    }
}

.grid-sidebar {
    min-width: $sidebar-width;
    background: lighten($gray-lightest, 7%);
    padding: 0 $padding-base;
    min-height: 100vh;

    + .grid-body {
        max-width: calc(100% - #{$sidebar-width});
        overflow-x: scroll;
    }
}

.grid-body {
    padding: 0 $padding-base;
}

.column {
    padding: 0 $padding-base;

    &.half-gutter {
        padding: 0 $padding-base/2;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.shadow-box {
    box-shadow: 0px 3px 10px #BFBFBF;
    margin-bottom: $margin-base;
    border-radius: 8px;
}

@media (max-width: '1700px'){
    .container {
        margin: $padding-base 0;
    }
}

@media (max-width: '1500px'){
    .container {
        margin-left: 0;
        margin-right: 0;
    }

    .row {
        > .mat-table {
            min-width: 900px;
            overflow-x: scroll;
        }
    }

    .grid-sidebar {
        min-width: $sidebar-width-sm;

        + .grid-body {
            max-width: calc(100% - #{$sidebar-width-sm});
        }
    }
}

@include breakpoint-max(viewport-sm){
    .grid-container {
        display: block;
    }
}



