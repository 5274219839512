mat-progress-bar {
    &.mat-progress-bar {
        height: 11px;
        border-radius: 5px;

        .mat-progress-bar-fill {
            &::after {
                background-color: $green-yellow-green;
                border-radius: 5px;
            }
        }
    }
}

.csv-loader {
    span {
        font-size: 1rem;
    }
}