button {
    &.button-primary {
        background: $button-color-default;
        font-family: $font-sans-condensed;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        color: $black;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-body;
        border-radius: $border-radius;
        border: none;
        padding: 20px;
        margin-bottom: $margin-base;
        margin-right: $margin-base;
        min-width: 280px;
        max-width: 100%;
        box-shadow: 0 6px 0 0 $button-color-shadow;

        &:hover {
            background: $button-color-hover;
            box-shadow: 0 6px 0 0 $button-color-hover;
            color: $white;
        }

        &.button-active {
            background: $button-color-default;
            box-shadow: none;
        }

        &:disabled {
            background: $button-color-disabled;
            box-shadow: none;
            opacity: 0.35;
        }

        &.button-warning {
            background: $button-color-warning;
            color: $white;
            box-shadow: 0 6px 0 0 $red-guardsman;

            &:hover {
                background: $button-color-warning-hover;
                box-shadow: 0 6px 0 0 $button-color-warning-hover;

            }

            &:disabled {
                background: $button-color-warning;
                box-shadow: none;
                opacity: 0.35;
            }
        }

        &.button-secondary {
            background: $gray-light;
            box-shadow: 0 6px 0 0 $gray-medium;

            &:hover {
                background: $gray-medium;
                color: $black;
                box-shadow: 0 6px 0 0 $gray-medium;
            }

            &.button-active {
                background: $gray-medium;
                box-shadow: none;
            }
    
            &:disabled {
                background: $gray-lightest;
                box-shadow: none;
                opacity: 0.35;
            }
        }
    }

    &.button-link {
        color: $gray-dark;
        text-decoration: underline;
        font-weight: normal;
        text-transform: uppercase;
        background: none;
        border: none;
        transition: 0.5s all;

        &:hover {
            color: $gray-medium;                
        }

        &.button-icon {
            text-decoration: none;

            &:hover {
                opacity: 0.5;   
                transition: 0.5s all;             
            }
            
            img {
                height: 1.5rem;
            }
        }
    }

    &.mat-raised-button {
        label &,
        h1 &,
        h2 & {
            box-shadow: none;
            border-radius: 50%;
            background: $gray-light;
            width: 20px;
            height: 20px;
            min-width: 0;
            padding: 5px;
            line-height: 1em;
            font-size: 24px;
            box-sizing: content-box;
            color: $white;
            margin: 0 $margin-base;
        }
    }

    .button-icon-inside {
        display: inline-block;
        box-shadow: none;
        border-radius: 50%;
        background: transparent;
        border: 1px solid $white;
        width: 1rem;
        height: 1rem;
        min-width: 0;
        padding: 2px;
        line-height: 0.9em;
        font-size: 21px;
        box-sizing: content-box;
        color: $white;
    }

}

.btn-upload {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background: $gray-light;
}

.button-group {
    display: flex;
    width: 556px;

    &.label-difficulty {
        > div {
            /* &:first-child {
                position: relative;

                &::before {
                    content: 'More Difficult';
                    position: absolute;
                    bottom: -2rem;
                    left: 0;
                    font-size: small;
                }
            }

            &:last-child {
                position: relative;

                &::after {
                    content: 'Less Difficult';
                    position: absolute;
                    bottom: -2rem;
                    right: 0;
                    font-size: small;
                }
            } */
        }
    }

    label {
        margin-bottom: 0;
    }

    > div {
        flex: 1 1 0px;

        .radio-custom, .radio-custom-label {
            vertical-align: middle;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .radio-custom-label {
            position: relative;
            background: $gray-lightest;
            padding: 10px;
            min-height: 3.5rem;
            width: 100%;
            text-align: center;
            box-sizing: border-box;
            border: 1px solid $gray-light;
            border-left-width: 0;
            text-transform: none;
    
            &:hover {
                background: $gray-light;
            }
        }
    
        .radio-custom {
            opacity: 0;
            position: absolute;   
            visibility: hidden; // prevent page jump on change
    
            &:focus, &:checked {
                + .radio-custom-label {
                    background: $green-sulu;
                }
            }
        }

        &:first-child {
            .radio-custom-label {
                border-radius: $border-radius 0 0 $border-radius;
                border-left-width: 1px;
            }
        }
        &:last-child {
            .radio-custom-label {
                border-radius: 0 $border-radius $border-radius 0;
            }
        }
    }

    &.button-group-auto {
        width: auto;

        > div {
            flex: inherit;

            label {
                padding-left: $padding-base;
                padding-right: $padding-base;
            }
        }
    }

}

mat-button-toggle-group.mat-button-toggle-group {
    border-radius: $border-radius;
    margin-bottom: $margin-base;
    border: 1px solid $gray-light;

    mat-button-toggle {
        background: $gray-lightest;
        align-items: center;
        display: flex;
        flex: 1 1 auto;

        &.mat-button-toggle-disabled {
            background: transparent;
            opacity: 0.5;
        }

        .mat-button-toggle-button {
            padding: 10px;
            box-sizing: border-box;
            box-shadow: none;
            border-radius: 0;
            font-family: $font-sans-condensed;
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
            color: $gray-dark;
            text-transform: none;
            letter-spacing: $letter-spacing-body;
            min-height: 3.5rem;

            &:hover {
                background: transparent;
            }

            .mat-button-toggle-label-content {
                line-height: $line-height-sm;
            }
        }

        &:hover {
            background: $gray-light;
        }

        &.mat-button-toggle-checked,
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
            background: $green-sulu;
        }
    }
}