.mat-tooltip {
    max-width: 340px !important;
    font-family: $font-sans;
    background: $gray-medium-dark;
    padding: 1rem;
    position: relative;
    margin-bottom: 1rem !important;
    text-align: left;
    color: $white;
    border-radius: 0;
    font-size: 1rem;
    line-height: 1.75em;
    white-space: pre-line;
}
