.mat-progress-spinner, .mat-spinner {
    circle {
        stroke: $gray-medium;
    }

    button & {
        display: inline;
        margin: 0 1rem;

        svg {
            width: 40px !important;
            height: 40px !important;
        }
    }
}