.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    white-space: pre-line;
}

// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
    .alert-#{$color} {
      @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
}