mat-expansion-panel {

    // &.game-settings {
    //     margin-bottom: $margin-base;

    //     .mat-expansion-panel-body {
    //         .container-flex {
    //             > div {
    //                 text-align: center;
    //                 margin-bottom: $margin-base;

    //                 .data-header {
    //                     font-size: $font-size-xl;
    //                     line-height: $line-height-md;
    //                     font-weight: $font-weight-bold;
    //                     text-transform: uppercase;
    //                     font-family: $font-sans-condensed;
    //                     color: $green-yellow-green;
    //                 }
    //             }
    //         }
    //     }
    // }

    .mat-dialog-title,
    .mat-expansion-panel-header,
    .mat-expansion-panel-content {
        font-family: $font-sans;
        line-height: $line-height-md;
    }
    
    .mat-expansion-panel-header-description, 
    .mat-expansion-panel-header-title {
        text-transform: uppercase;
        color: $gray-medium-dark;
        font-family: $font-sans-condensed;
        font-size: $font-size-l;
    }
    
}
