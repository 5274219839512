mat-dialog-container.mat-dialog-container {

    .mat-dialog-content {
        text-align: center;
        font-size: $font-size-l;
        color: $gray-dark;
        margin-bottom: $margin-base;
    }

    .mat-dialog-actions {
        justify-content: center;

        button {
            &:not(.button-primary) {
                display: block;
                font-family: $font-sans-condensed;
                font-weight: $font-weight-bold;
                font-size: $font-size-l;
                margin-bottom: 1.275em;
                text-transform: uppercase;
                color: $gray-medium;
                width: auto;
                min-width: 120px;

                &:hover,
                &:focus {
                    color: $black;
                }

                .mat-button-focus-overlay {
                    opacity: 0;
                }
            }

            &.button-primary {
                width: auto;
                min-width: 120px;
            }
        }
    }
}