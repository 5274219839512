$font-sans : "Open Sans", sans-serif;
$font-sans-condensed : "Open Sans Condensed", sans-serif;

$font-weight-normal: 400;
$font-weight-bold:   700;


/*  Font Sizes */
$font-size-base : 18px;
$font-size-md : 1.167rem; /* 21px */
$font-size-l : 1.333rem; /* 24px */
$font-size-xl : 2.222rem; /* 40px */
$font-size-xxl : 3.444rem; /* 62px */
$font-size-xxxl : 4.111rem; /* 74px */
$font-size-banner : 10.778rem; /* 194px */

$letter-spacing-body: 0.24px;
$letter-spacing-heading: 0.62px; 

$line-height-sm: 1.083em;
$line-height-md: 1.36em;
$line-height-lg: 1.72em;


h1, h2, h3, h4, h5, h6 {
    font-family: $font-sans-condensed;
    font-weight: $font-weight-bold;
    line-height: $line-height-md;
    text-transform: uppercase;
    color: $gray-dark;
}

h1 {
    font-size: $font-size-xxxl;
}

h2 {
    font-size: $font-size-xl;
}

h3 {
    font-size: $font-size-l;
    color: $gray-medium-dark;
}

.content,
p {
    font-size: $font-size-md;
    color: $gray-medium-dark;
}

.breadcrumb {
    margin: $margin-base 0;
    ol {
        list-style: none;
        
        li {
            font-weight: $font-weight-normal;
            letter-spacing: 1.51px;
            text-transform: uppercase;
            color: $gray-dark;
            display: inline-block;

            a {
                color: $gray-dark;
            }

            &:not(:first-child)+li:before {
                content: ">";
                margin: 0 5px;
            }
        }
    }
}


.text-light {
    color: $gray-medium-dark;
}

.text-highlight {
    color: $green-yellow-green;
}

.empty-container {
    p {
        font-size: $font-size-xl;
        line-height: 2em;
        background-color: $gray-medium;
        color: transparent;
        text-shadow: 2px 2px 2px rgba($gray-lightest, 0.5);
        -webkit-background-clip: text;
           -moz-background-clip: text;
                background-clip: text;
    }
}
