.d-block {
  display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-none {
  display: none !important;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100% !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-underline {
  text-decoration: underline;
}

/* 
// MARGIN & PADDING - stolen mostly from Bootstrap
*/

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 2),
  5: ($spacer * 3)
), $spacers);

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {

      .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
      .#{$abbrev}t-#{$size},
      .#{$abbrev}y-#{$size} {
        #{$prop}-top: $length !important;
      }
      .#{$abbrev}r-#{$size},
      .#{$abbrev}x-#{$size} {
        #{$prop}-right: $length !important;
      }
      .#{$abbrev}b-#{$size},
      .#{$abbrev}y-#{$size} {
        #{$prop}-bottom: $length !important;
      }
      .#{$abbrev}l-#{$size},
      .#{$abbrev}x-#{$size} {
        #{$prop}-left: $length !important;
      }
    }
}