.login-container {
    position: relative;

    &::after {
        content: "";
        background: url(/../../assets/images/TitleScreen-50percentOpacity.png) no-repeat 0 0/cover;
        opacity: 1;
        top: 0;
        left: -2rem;
        bottom: 0;
        right: -2rem;
        position: fixed;
        z-index: -1;
      }

    form {
        background: $white;
    }

    .ja-logo {
        background-color: #403666;
        border-radius: 10px;
        padding: 4px;
    }

    .titan-logo {
        margin-left: 25px;
        margin-top: -35px;
        margin-bottom: -25px;
    }
}
