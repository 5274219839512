$company_subheader : 140;

.company-subheader {
    height: $company_subheader + 'px';
}

.team-selection-table,
.company-review-list {
    min-width: 400px;
    max-width: 50%;
    border: 1px solid $gray-lightest;
    overflow-y: scroll;
    max-height: calc(100% - 188px); // company_subheader + m-5 (3rem = 48px)

    .userResizable & {
        max-height: calc(100% - 2rem);
    }

    .mat-checkbox-frame {
        border-color: rgba($gray-medium, 0.54);
    }

    .mat-checkbox-checked.mat-accent  {
         .mat-checkbox-background {
            background-color: $green-yellow-green;
        }
    }

    .cell-selected {
        background-color: $gray-lightest;
    }

    .create-table {
        min-width: 100px;
    }

}

.team-member-list {
    li {
        font-weight: $font-weight-normal;
        color: $gray-medium-dark;
    }
}

.quarter-box {
    .row {
        max-height: 11.25rem;
        overflow-y: scroll;
    }

    // &.quarter-select-box {}

    .quarter-current {
        color: $green-yellow-green;
        font-size: $font-size-xl;
        width: 4.5rem;
        line-height: $line-height-md;
        font-family: $font-sans-condensed;
        background-position-y: 50%;
        margin-bottom: 0;
    }
    span {
        text-transform: uppercase;
        color: $gray-medium-dark;
    }
}

.team-box {
    .row {
        max-height: 11.25rem;
        overflow-y: scroll;
    }
}

.game-settings {
    .container-flex {
        > div {
            text-align: center;
            margin-bottom: $margin-base;
            max-width: 33%;

            .data-header {
                font-size: $font-size-xl;
                line-height: $line-height-md;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                font-family: $font-sans-condensed;
                color: $green-yellow-green;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.review-start-time {
    span {
        color: $green-yellow-green;
        font-size: $font-size-xl;
        width: 4rem;
        line-height: $line-height-md;
        font-family: $font-sans-condensed;
    }
}

.company-member-list {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
